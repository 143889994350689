import styled from "styled-components"

export const ClientsContainer = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    flex-direction: column;

    .row-gap{
        grid-template-columns: repeat(6, 200px);
        padding: 36px 0;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 200px);
        }
    }
`
export const ClientsH2 = styled.h2`
    color: #000;
    font-size: 2.5rem;
    font-weight: bold;
`

export const ClientsLine = styled.div`
    background: #DE7A48;
    height: 2px;
    width: 100px;
    margin: 50px 0;
`

export const ClientsWrapper = styled.div`
    max-width: 1100px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 200px);
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
    }
`

export const ClientsLogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
`
export const ClientsLogo = styled.img`
    width: auto;
    transition: all 0.3s ease-in-out;
    transform: scale(0.3);
    @media screen and (max-width: 768px){
        width: 100%;
    }
`