import React from 'react'
import Slider from 'react-slick'

import { PartnersContainer, PartnerLogo, Main, PartnerLogoContainer, PartnersH2, PartnersLine } from './PartnersComponents'
const Partners = (lightBg) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: true,
  };
  return (
      <Main lightBg={lightBg}>
        <PartnersContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/shantui.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="big" alt="shantui" src={require("../../images/logos/weichai.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/xcmg.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/shacman.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/zhong.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="big" alt="shantui" src={require("../../images/logos/baoduin.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" className='medium' src={require("../../images/logos/sdec.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/howo.png")}></PartnerLogo>
          </PartnerLogoContainer>
        </PartnersContainer>
      </Main>
  )
}

export default Partners