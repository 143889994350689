import styled from "styled-components";

export const PerksContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;
`

export const PerksH2 = styled.h2`
    text-align: center;;
    color: black;
    font-size: 2.5rem;
    grid-gap: 20px;
    padding: 0 20px;
    margin-bottom: 100px;
    font-weight: bold;
    @media screen and (max-width:768px){
        font-size: 2rem;
        text-align: left;
    }
`

export const PerksCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 100px;
    column-gap:50px;
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;

    }
`

export const PerksCard = styled.div`
    width: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    gap: 10px
`
export const PerksCardIcon = styled.div`
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #DE7A48;
    border-radius: 100%;
    font-size: 36px;
`
export const PerksCardH3 = styled.h3`
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 24px;
    height: 48px;
`
export const PerksCardP = styled.p`
    text-align: center;
    color:#8c8c8c;
    font-size: 16px;
`

export const Br = styled.br`
    display: block;
`