import React from 'react'
import {FooterContainer, FooterLink, FooterLinkItems, FooterLinkTitle, FooterLinksContainer, FooterLinksWrapper, FooterWrap} from './FooterElements'
import { SocialMedia, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, SocialMediaWrap} from './FooterElements'
import {FaFacebookF, FaLinkedin, FaInstagram, FaYoutube} from 'react-icons/fa'
import {TbBrandTelegram} from 'react-icons/tb'
const Footer = () => {
  return (
      <FooterContainer>
          <FooterWrap>
              <FooterLinksContainer>
                  <FooterLinksWrapper>
                      <FooterLinkItems>
                        <FooterLinkTitle>About us</FooterLinkTitle>
                            <FooterLink href="//www.instagram.com">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                      </FooterLinkItems>
                      <FooterLinkItems>
                        <FooterLinkTitle>About us</FooterLinkTitle>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                      </FooterLinkItems>
                  </FooterLinksWrapper>
                  <FooterLinksWrapper>
                      <FooterLinkItems>
                        <FooterLinkTitle>About us</FooterLinkTitle>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                      </FooterLinkItems>
                      <FooterLinkItems>
                        <FooterLinkTitle>About us</FooterLinkTitle>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                            <FooterLink href="/">About us</FooterLink>
                      </FooterLinkItems>
                  </FooterLinksWrapper>
              </FooterLinksContainer>
              <SocialMedia>
                  <SocialMediaWrap>
                      <SocialLogo to='/'><><img alt="logo" src={require('../../images/logos/logo_orng.png')}/></></SocialLogo>
                      <WebsiteRights>TAS Motors © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                      <SocialIcons>
                          <SocialIconLink href="//" target="_blank" aria-label="Facebook">
                              <FaFacebookF />
                          </SocialIconLink>
                          <SocialIconLink href="//" target="_blank" aria-label="Instagram">
                              <FaInstagram />
                          </SocialIconLink>
                          <SocialIconLink href="https://t.me/tasmotors" target="_blank" aria-label="Telegram">
                              <TbBrandTelegram />
                          </SocialIconLink>
                          <SocialIconLink href="//" target="_blank" aria-label="Linkedin">
                              <FaLinkedin />
                          </SocialIconLink>
                      </SocialIcons>
                  </SocialMediaWrap>
              </SocialMedia>
          </FooterWrap>
      </FooterContainer>
  )
}

export default Footer