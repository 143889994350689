import styled from "styled-components";
import background from '../../images/contact.png'
export const ContactContainer = styled.section`
    padding: 100px 0;
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${background}) ;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
`

export const ContactH2 = styled.h2`
    color: white;
    margin-bottom: 60px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.1;


    @media screen and (max-width: 480px){
        font-size: 32px;;
    }
`

export const FormWrapper = styled.form`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 60px;

    @media screen and (max-width: 768px){
        gap: 30px;
    }
`
export const FormInputWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    @media screen and (max-width: 768px){
        flex-direction: column;
        gap: 30px;
    }
`

export const FormInput = styled.input`
    display:block;
    width:480px;
    height: 64px;
    padding:.9em;
    font-size:20px;
    background-color:#fbfbfb;
    border:solid 1px #CCC;
    resize:vertical;
    border-radius: 5px;
    &::placeholder {
        color: #d9d5d4;
        font-size: 16px;
        
    }
    &:active {
        border: solid #DE7A48 1px;
        background: #d9d5d4;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    @media screen and (max-width: 768px){
        width: 360px;
        height: 54px;
        font-size: 16px;
    }
`
export const FormTextArea = styled.textarea`
    display:block;
    width:100%;
    padding:.3em;
    font-size:20px;
    background-color:#fbfbfb;
    border:solid 1px #CCC;
    resize:vertical;

`

export const Button = styled.input`
    border-radius: 5px;
    background: #DE7A48;
    white-space: nowrap;
    padding: 20px 36px;
    color: #fff;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    justify-self: end;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #cb7246;
        color: white;
    }
`

export const FormLabel = styled.label`
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
`

export const FormLabelInput = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`