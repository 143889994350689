import React from 'react'
import { ContactContainer, IFrame, MapAndContactsContainer, ContactH2, ContactCard, ContactIcon, ContactP, ContactA, QR} from './MapComponents'
import {HiLocationMarker, HiPresentationChartBar} from 'react-icons/hi'
import {MdEmail} from 'react-icons/md'
import {BsFillTelephoneFill} from 'react-icons/bs'

const MapCont = () => {
    return (
    <MapAndContactsContainer id="contacts">
        <IFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21353.748417495648!2d69.18890029280945!3d41.1885776053437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae6171bd688a73%3A0x39a2d58fe6d8fb4d!2sTAS%20Motors!5e0!3m2!1sen!2s!4v1655933765405!5m2!1sen!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></IFrame>
        <ContactContainer>
            <ContactH2>Контактная инофрмация</ContactH2>
            <ContactCard>
                <ContactIcon><BsFillTelephoneFill /></ContactIcon>
                <ContactP>+998 (71) 283-00-07, +998 (95) 364-00-07</ContactP>
            </ContactCard>
            <ContactCard>
                <ContactIcon><HiLocationMarker /></ContactIcon>
                <ContactP>г. Ташкент, 100012, Янгихаетский район, Дустлик 1</ContactP>
            </ContactCard>
            <ContactCard>
                <ContactIcon><MdEmail /></ContactIcon>
                <ContactP>contact@tasmotors.uz</ContactP>
            </ContactCard>
            <ContactCard>
                <ContactIcon><HiPresentationChartBar /></ContactIcon>
                <ContactA href="./directory/yourfile.pdf" download="newfilename">Скачать презентацию компании</ContactA>
            </ContactCard>
            <QR alt='qr-code' src={require('../../images/frame.png')}/>
        </ContactContainer>
    </MapAndContactsContainer>
    );
}

export default MapCont

