import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/sidebar'
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/infoSection';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/infoSection/data';
import Services from '../components/Services';
import Footer from '../components/Footer';
import ContactForm from '../components/contactform';
import Perks from '../components/perks';
import MapCont from '../components/MapAndContacts';
import Partners from '../components/Partners';
import SimpleSlider from '../components/HeroSection/index1';
import Clients from '../components/Clients';
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };


  return (
      <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <SimpleSlider />
        <InfoSection {...homeObjOne}/>
        <Partners lightBg={true}/>
        <Perks />
        <InfoSection {...homeObjTwo}/>
        <InfoSection {...homeObjThree}/>
        <MapCont />
        <Clients />
        <ContactForm />
        <Footer />
      </>

  )
}

export default Home