import React from 'react'
import {FaMoneyCheckAlt,FaDatabase, FaGraduationCap, FaTools} from 'react-icons/fa'
import { PerksContainer, PerksH2, PerksCard, PerksCardsWrapper, PerksCardH3, PerksCardIcon, PerksCardP } from './PerksElements'
import {AiFillSafetyCertificate, AiOutlineTrademarkCircle} from 'react-icons/ai'
import {ImLocation2} from 'react-icons/im'
const Perks = () => {
  return (
      <PerksContainer id='perks'>
          <PerksH2>Преимущества работы с нашим сервисом</PerksH2>
          <PerksCardsWrapper>
              <PerksCard>
                <PerksCardIcon><FaMoneyCheckAlt /></PerksCardIcon>
                <PerksCardH3>Гибкая система оплаты</PerksCardH3>
                <PerksCardP>Для постоянных клиентов существует возможность проводить оплаты по факту выполнения работ. </PerksCardP>
              </PerksCard>
              <PerksCard>
                <PerksCardIcon><AiFillSafetyCertificate /></PerksCardIcon>
                <PerksCardH3>Гарантия</PerksCardH3>
                <PerksCardP>Предоставляется гарантия на все виды работ и запчастей, приобретенных в сервисном центре, от 3-х до 12 месяцев. </PerksCardP>
              </PerksCard>
              <PerksCard>
                <PerksCardIcon><AiOutlineTrademarkCircle /></PerksCardIcon>
                <PerksCardH3>Оригинальные запчасти</PerksCardH3>
                <PerksCardP>Оригинальные запасные части (поставки напрямую от заводов WEICHAI, XCMG, SHACMAN, SHANTUI, HOWO).</PerksCardP>
              </PerksCard>
              <PerksCard>
                <PerksCardIcon><ImLocation2 /></PerksCardIcon>
                <PerksCardH3>Мобильность</PerksCardH3>
                <PerksCardP>Мобильные сервисные машины, оборудованные всем необходимым для выездных работ и ремонта в полевых условиях.</PerksCardP>
              </PerksCard>
              <PerksCard>
                <PerksCardIcon><FaDatabase /></PerksCardIcon>
                <PerksCardH3>Электронная база данных</PerksCardH3>
                <PerksCardP>Собственная электронная база данных по ремонту. Все заказ наряды регистрируются в базе данных по VIN номеру. </PerksCardP>
              </PerksCard>
              <PerksCard>
                <PerksCardIcon><FaGraduationCap /></PerksCardIcon>
                <PerksCardH3>Квалифицированный персонал </PerksCardH3>
                <PerksCardP>Квалифицированный персонал и наличие инженерно-технического отдела для решения вопросов по ремонту сложных узлов и агрегатов. </PerksCardP>
              </PerksCard>
              <PerksCard>
                <PerksCardIcon><FaTools /></PerksCardIcon>
                <PerksCardH3>Оборудование</PerksCardH3>
                <PerksCardP>Оснащение сервисного центра специальным инструментом и оборудованием, диагностическими сканерами любых двигателей.   </PerksCardP>
              </PerksCard>
          </PerksCardsWrapper>
      </PerksContainer>
  )
}

export default Perks