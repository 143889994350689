import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'

export const HeroContainer = styled.div`
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${(props)=>props.imgUrl}); 
    background-repeat: no-repeat;
    background-size: cover;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 30px;
    height: 900px;
    .arrow {
        color: white;
        font-size: 40px;
        cursor: pointer;
    }
`

export const HeroContent = styled.div`
    display:flex;
    padding: 8px 24px;
`

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    @media screen and (max-width: 768px){
        font-size:40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size:24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }

`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`