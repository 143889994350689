import React from 'react'
import { ClientsContainer, ClientsWrapper, ClientsLogoWrapper, ClientsLogo, ClientsH2, ClientsLine } from './ClientsComponents'
import { PartnersContainer, PartnerLogo, PartnerLogoContainer } from '../Partners/PartnersComponents'

const Clients = () => {
  return (
      <ClientsContainer>
          <ClientsH2>Наши Клиенты</ClientsH2>
          <ClientsLine className=''></ClientsLine>
        <PartnersContainer className='row-gap'>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/partners/colored/tmz.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="small" alt="shantui" src={require("../../images/logos/partners/colored/chortoq.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="small" alt="shantui" src={require("../../images/logos/partners/colored/bekabadcement.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/durablebeton.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/ecocement.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/enter.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="small" alt="shantui" src={require("../../images/logos/partners/colored/fvv.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/jizzax.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/jizzax.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/mimarsinan.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/mirankul.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="medium" alt="shantui" src={require("../../images/logos/partners/colored/eurocement.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/partners/colored/mls.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo className="small" alt="shantui" src={require("../../images/logos/partners/colored/tdm.png")}></PartnerLogo>
          </PartnerLogoContainer>
          <PartnerLogoContainer>
            <PartnerLogo alt="shantui" src={require("../../images/logos/partners/colored/uls.png")}></PartnerLogo>
          </PartnerLogoContainer>
        </PartnersContainer>
      </ClientsContainer>
  )
}

export default Clients