import styled from "styled-components";

export const Main = styled.div`
    background: ${({lightBg}) => (lightBg ? 'white' : 'black')};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 0 0;
`

export const PartnersContainer = styled.div`
    max-width: 1100px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 200px);
    row-gap: 64px;
    @media screen and (max-width: 768px){
        grid-template-columns: repeat(2, 200px);
    }
`

export const PartnerLogo = styled.img`
    height: 30px;
    width: auto;
    filter: grayscale(100%) opacity(50%);
    transition: all 0.3s ease-in-out;
    &:hover {
        transition: all 0.3s ease-in-out;
        filter: none;
    }
`
export const PartnerLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .small {
        transform: scale(2);
    }
    .medium {
        transform: scale(1.5);
    }
    .big {
        transform: scale(0.8);
    }
`

export const PartnersH2 = styled.h2`
    margin-top: 100px;
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
`

export const PartnersLine = styled.div`
    background: #DE7A48;
    height: 2px;
    width: 100px;
    margin: 50px 0;
`