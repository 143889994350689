import React from 'react'
import {
    SideBarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    SidebarRoute
} from './sideBarElements'
const Sidebar = ({isOpen, toggle}) => {
  return (
      <SideBarContainer isOpen={isOpen} onClick={toggle}>
          <Icon onClick={toggle}>
              <CloseIcon />
          </Icon>
          <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink onClick={toggle} to="about">О нас</SidebarLink>
                <SidebarLink onClick={toggle} to="perks">Преимущества</SidebarLink>
                <SidebarLink onClick={toggle} to="services">Услуги</SidebarLink>
                <SidebarLink onClick={toggle} to="contacts">Контакты</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/signin">Оставить заявку</SidebarRoute>
            </SideBtnWrap>
          </SidebarWrapper>
      </SideBarContainer>
  )
}

export default Sidebar