import styled from "styled-components";
export const MapAndContactsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const MapContainer = styled.div`
    width:500px;
    height:500px;
`

export const IFrame = styled.iframe`
    height: 600px;
    flex: 1;


    @media screen and (max-width: 768px){
        display: none;
    }
`

export const ContactContainer = styled.div`
    padding: 48px;
    flex: 1;
    background: #101010;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`

export const ContactH2 = styled.h2`
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.1;;
    color: white;

    @media screen and (max-width: 480px){
        font-size: 24px;;
    }
`

export const ContactCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 24px;;
`

export const ContactIcon = styled.div`
    font-size: 24px;
    color: #DE7A48;
    margin-right: 12px;
    display: flex;
    align-items: center;
`
export const ContactP = styled.p`
    font-size: 20px;
    max-width: 500px;
    color: #ccc;
`
export const ContactA = styled.a`
    font-size: 20px;
    color: #ccc;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        color: #DE7A48;
    }
`

export const QR = styled.img`
    height: 140px;
    width: auto;
`