import React, {useRef} from 'react'
import emailjs from 'emailjs-com';

import { ContactContainer, ContactH2, FormWrapper, FormInputWrapper, FormInput, FormTextArea, Button, FormLabel, FormLabelInput } from './CFcomponents'
const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_c6ouzsj', 'template_k6ok194', form.current, 'kqL1q0RurU3OwvUYt')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    return (
      <ContactContainer id="contactform">
          <ContactH2>Оставить Заявку</ContactH2>
          <FormWrapper ref={form} onSubmit={sendEmail}>
              <FormInputWrapper>
                  <FormLabelInput>
                    <FormLabel>Наименование компании</FormLabel>
                    <FormInput type={"text"} name="companyName" placeholder='TAS Motors...'/>
                  </FormLabelInput>
                  <FormLabelInput>
                    <FormLabel>Контактное лицо</FormLabel>
                    <FormInput type={"text"} name="contactName" placeholder='Ismoil Aburahmanov...'/>
                  </FormLabelInput>
              </FormInputWrapper>
              <FormInputWrapper>
                  <FormLabelInput>
                    <FormLabel>Телефон для обратной связи</FormLabel>
                    <FormInput type={"number"} name="number" placeholder='998909999999...'/>
                  </FormLabelInput>
                  <FormLabelInput>
                    <FormLabel>Модель техники</FormLabel>
                    <FormInput type={"text"} name="model" placeholder='Shacman...'/>
                  </FormLabelInput>
              </FormInputWrapper>
              <FormLabelInput>
                <FormLabel>Описание проблемы</FormLabel>
                <FormTextArea rows={6} name="problem"></FormTextArea>
              </FormLabelInput>
            <Button type="submit" value={"Отправить заявку"}/>
          </FormWrapper>
      </ContactContainer>
    )
}

export default ContactForm