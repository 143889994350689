import React, {useEffect, useState} from 'react'
import {FaBars} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll/modules';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink
} from './NavbarElements';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }
  return (
      <>
        <Nav scrollNav={scrollNav }>
            <NavbarContainer>
                <NavLogo to="/" onClick={toggleHome}><><img alt="logo" src={require('../../images/logos/logo_orng.png')}/></></NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to ="about"
                        smooth
                        duration={500} 
                        spy
                        exact='true'
                        offset={-80}
                        scrollNav={scrollNav }
                        >О нас</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ="perks"
                        smooth
                        duration={500} 
                        spy
                        exact='true'
                        scrollNav={scrollNav }
                        offset={-80}>Преимущества</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ="services"
                        smooth
                        duration={500} 
                        spy
                        exact='true'
                        scrollNav={scrollNav }
                        offset={-80}>Услуги</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ="machinery"
                        smooth
                        duration={500} 
                        spy
                        exact='true'
                        scrollNav={scrollNav }
                        offset={-80}>Запчасти</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ="contacts"
                        smooth
                        duration={500} 
                        spy
                        exact='true'
                        scrollNav={scrollNav }
                        offset={-80}>Контакты</NavLinks>
                    </NavItem>
                </NavMenu>
                <NavBtn>
                    <NavBtnLink to="contactform"
                        smooth
                        duration={500} 
                        spy
                        exact='true'
                        scrollNav={scrollNav }
                        offset={-80}>Оставить заявку</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
      </>
  );
};

export default Navbar;