export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Lorem Ipsum',
    headline: 'О нас',
    description: "TAS MOTORS входит в группу компаний TEXNIKA ADVANS SERVIS (TAS), занимающейся поставками спецтехники и оборудования. С момента основания компании TAS одним из главных направлений деятельности было гарантийное /  послегарантийное обслуживание и поставки оригинальных запасных частей для спецтехники. Начиная с 2018г. на базе функционирующего  сервисного центра была создана сервисная компания TAS MOTORS. В настоящее время TAS MOTORS является авторизованным сервисным центром крупнейших производителей спецтехники и коммерческого транспорта (XCMG, SHACMAN, SHANTUI, WEICHAI POWER и др.).  ",
    buttonLabel: 'Связаться',
    imgStart: false,
    img: require('../../images/building.jpg'),
    alt: 'alt',
    dark: false,
    primary: true,
    darkText: true
}

export const homeObjTwo = {
    id: 'service-center',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Lorem Ipsum',
    headline: 'Сервисный центр',
    description: "Ремзона более 900 кв.м. рассчитана на  8 машинопостов (длиной 16м), из них 7 оснащены смотровыми ямами. Все машинопосты оборудованы отдельными подъемными воротами (высота 4,5м). Ремзона оборудована кран-балкой грузоподъемностью до 3т.  Каждый машинопост оборудован комплектом инструментов и оборудованием, позволяющим производить любые виды ремонтных работ. Для отвода выхлопных газов установлена система Совплим (Россия). Прилегающая территория более 2 400 кв.м. позволяет одновременно производить ремонт и техобслуживание до 20 ед. техники различных габаритов. ", //not more than 100
    buttonLabel: 'Обратный звонок',
    imgStart: true,
    img: require('../../images/service_center.JPG'),
    alt: 'alt',
    dark: false,
    primary: true,
    darkText: true
}

export const homeObjThree = {
    id: 'machinery',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Lorem Ipsum',
    headline: 'Склад запчастей',
    description: "Для эффективного использования площади склада в сервисном центре была установлена 3-х ярусный складской мезонин. Полезная площадь хранения запчастей в помещении более 1 200 кв.м. (при этом занимаемая площадь склада 216 кв.м.). Производитель компания Solos, Россия. Система адресного хранения позволяет автоматический оптимизировать хранение запасных частей исходя из габаритов, веса и объема складируемой продукции. Также имеется открытая площадка для хранения крупногабаритных запчастей (двигатели, КПП, редуктора и т.п.) площадью более 200 кв.м. Для учета движений ТМЦ на складе используется программа на базе 1С. В наличии оригинальные запасные части XCMG, WEICHAI POWER, SHANTUI, SHACMAN. Всегда в наличии расходные материалы и фильтра. Также отдел запасных частей предлагает услуги по оперативной поставке запасных частей под заказ из КНР.", //not more than 100
    buttonLabel: 'Связаться',
    imgStart: false,
    img: require('../../images/machinery.jpg'),
    alt: 'alt',
    dark: false,
    primary: true,
    darkText: true
}