import React, {useState, useRef} from "react";
import Slider from "react-slick";
import { HeroContainer, HeroContent, HeroH1} from './HeroElements'
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai'

export default function SimpleSlider() {

  //creating the ref
  const customeSlider = useRef();

  var settings = {
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,

  };
  const previous = () => {
    customeSlider.current.slickNext();
  };

  const next = () => {
    customeSlider.current.slickPrev();
  };
  return (
    <Slider {...settings} ref={customeSlider}>
        {/* <HeroSection className="dotPlace" imgUrl={'images/4.JPG'}/>
        <HeroSection className="dotPlace" imgUrl={'images/building.jpg'}/>
        <HeroSection className="dotPlace" imgUrl={'images/worker.JPG'}/>
        <HeroSection className="dotPlace" imgUrl={'images/worker.JPG'}/>
        <HeroSection className="dotPlace" imgUrl={'images/worker.JPG'}/>  */}

       
      <HeroContainer imgUrl={'images/4.JPG'}>
        <AiOutlineArrowLeft className='arrow' onClick={()=>next()}/>
          <HeroContent>
              <HeroH1>Качественный ремонт спецтехники</HeroH1>
          </HeroContent>
          <AiOutlineArrowRight className='arrow' onClick={()=>previous()}/>
      </HeroContainer>
      <HeroContainer imgUrl={'images/building.jpg'}>
        <AiOutlineArrowLeft className='arrow' onClick={()=>next()}/>
          <HeroContent>
              <HeroH1>Оригинальные запчасти</HeroH1>
          </HeroContent>
          <AiOutlineArrowRight className='arrow' onClick={()=>previous()}/>
      </HeroContainer>
      <HeroContainer imgUrl={'images/worker.JPG'}>
        <AiOutlineArrowLeft className='arrow' onClick={()=>next()}/>
          <HeroContent>
              <HeroH1>Гарантия на все виды работ</HeroH1>
          </HeroContent>
          <AiOutlineArrowRight className='arrow' onClick={()=>previous()}/>
      </HeroContainer>
      <HeroContainer imgUrl={'images/worker.JPG'}>
        <AiOutlineArrowLeft className='arrow' onClick={()=>next()}/>
          <HeroContent>
              <HeroH1>Выездные работы</HeroH1>
          </HeroContent>
          <AiOutlineArrowRight className='arrow' onClick={()=>previous()}/>
      </HeroContainer>
      <HeroContainer imgUrl={'images/worker.JPG'}>
        <AiOutlineArrowLeft className='arrow' onClick={()=>next()}/>
          <HeroContent>
              <HeroH1>Авторизованный сервисный центр</HeroH1>
          </HeroContent>
          <AiOutlineArrowRight className='arrow' onClick={()=>previous()}/>
      </HeroContainer>
    

    </Slider>
  );
}