import styled from "styled-components";

export const ServicesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 100px 0;
`

export const ServicesWrapper = styled.div`
    max-width: 1250px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
    padding: 0 20px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    min-height: 300px;
    max-height: 340px;
    gap: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    filter: opacity(70%);
    transition: all 0.2s ease-in-out;
    min-width: 300px;
    &:hover {
        filter: none;
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const ServicesIcon = styled.div`
    font-size: 48px;
    margin-bottom: 10px;
    color: #282A2D;
    ${ServicesCard}:hover &{
        color: #DE7A48;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: 470px){
        font-size: 2rem;
    }
`
export const ServicesH3 = styled.h3`
    font-size: 1rem;
    color: #282A2D;
    text-align: center;
    padding: 0 24px;
`
export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
    color: #282A2D;

`
export const ServicesCardImg = styled.img`
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
`